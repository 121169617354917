import { default as _91_46_46_46404_935HoX6yqDILMeta } from "/app/pages/[...404].vue?macro=true";
import { default as dashboardwmFYamop5DMeta } from "/app/pages/app/dashboard.vue?macro=true";
import { default as indexbUTYpCP6oZMeta } from "/app/pages/app/index.vue?macro=true";
import { default as login6rSjLiwWWjMeta } from "/app/pages/app/login.vue?macro=true";
import { default as logoutAXTEOEHA6IMeta } from "/app/pages/app/logout.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as terms_45of_45useA3Owpnmw23Meta } from "/app/pages/terms-of-use.vue?macro=true";
export default [
  {
    name: "404___de",
    path: "/:404(.*)*",
    meta: _91_46_46_46404_935HoX6yqDILMeta || {},
    component: () => import("/app/pages/[...404].vue")
  },
  {
    name: "404___en",
    path: "/en/:404(.*)*",
    meta: _91_46_46_46404_935HoX6yqDILMeta || {},
    component: () => import("/app/pages/[...404].vue")
  },
  {
    name: "app-dashboard___de",
    path: "/app/dashboard",
    component: () => import("/app/pages/app/dashboard.vue")
  },
  {
    name: "app-dashboard___en",
    path: "/en/app/dashboard",
    component: () => import("/app/pages/app/dashboard.vue")
  },
  {
    name: "app___de",
    path: "/app",
    meta: indexbUTYpCP6oZMeta || {},
    component: () => import("/app/pages/app/index.vue")
  },
  {
    name: "app___en",
    path: "/en/app",
    meta: indexbUTYpCP6oZMeta || {},
    component: () => import("/app/pages/app/index.vue")
  },
  {
    name: "app-login___de",
    path: "/app/login",
    component: () => import("/app/pages/app/login.vue")
  },
  {
    name: "app-login___en",
    path: "/en/app/login",
    component: () => import("/app/pages/app/login.vue")
  },
  {
    name: "app-logout___de",
    path: "/app/logout",
    meta: logoutAXTEOEHA6IMeta || {},
    component: () => import("/app/pages/app/logout.vue")
  },
  {
    name: "app-logout___en",
    path: "/en/app/logout",
    meta: logoutAXTEOEHA6IMeta || {},
    component: () => import("/app/pages/app/logout.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "privacy___de",
    path: "/privacy",
    component: () => import("/app/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/app/pages/privacy.vue")
  },
  {
    name: "terms-of-use___de",
    path: "/terms-of-use",
    component: () => import("/app/pages/terms-of-use.vue")
  },
  {
    name: "terms-of-use___en",
    path: "/en/terms-of-use",
    component: () => import("/app/pages/terms-of-use.vue")
  }
]